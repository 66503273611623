export function getCypressId (param: string) {
  const route = useRoute()
  const { navigation, profileNavigation } = useNavigation()

  const allItems = [...navigation, ...profileNavigation]
    .map((item: any) => {
      if (item.subMenu)
        return [...item.subMenu, item]

      return item
    })
    .flat().filter((item: any) => item.href !== '/') as { href: string, key: string }[]

  const sanitizedPath = route.path.replaceAll('/', '')
  const navigationItem = allItems.find((item: any) => item.href.replaceAll('/', '') === sanitizedPath)?.key ?? ''

  const finalPath = navigationItem.length < 1 ? 'home' : navigationItem

  return `${finalPath}-${param}`
}
